/* client/src/components/Navbar.css */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: relative;
    z-index: 1000;
    width: 100%;
  }
  
  
  .logo-link {
    text-decoration: none;
    color: #ffff;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logoImage {
    height: 60px;
    width: auto;
    object-fit: contain;
  }
  
  /* Desktop Menu Styles */
  .links {
    display: none; /* Hidden by default on mobile */
  }
  
  @media (min-width: 1000px) {
    .links {
      display: flex;
      gap: 2rem;
      font-family: 'Open-Sans', sans-serif;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1000px){
    .logo {
        font-size: 1.2rem;
        font-weight: bold;
    }
    .nav{
        padding-top: 10px;
    }
  }
  
  .link {
    text-decoration: none;
    color: #ffff;
    padding: 0.5rem;
    transition: color 0.3s ease;
    display: flex;
  }
  
  .activeLink {
    color: #808080;
    font-weight: bold;
  }
  
  /* Dropdown Styles */
  .dropdownContainer {
    position: relative;
    display: inline-block;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #333;
    border-radius: 4px;
    padding: 0.5rem 0;
    min-width: 150px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .dropdownLink {
    display: block;
    padding: 0.5rem 1rem;
    color: #ffff;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .dropdownLink:hover {
    background-color: rgba(140, 140, 140, 1);
  }
  
  /* Mobile Menu Styles */
  .mobile-menu-button {
    display: block; /* Show on mobile */
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  @media (min-width: 1000px) {
    .mobile-menu-button {
      display: none; /* Hide on desktop */
    }
    .logo {
        font-size: 2.5rem;
        font-weight: bold;
    }
  }
  
  .mobile-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: black;
    border-top: 1px solid #333;
    padding: 1rem 0;
  }
  
  .mobile-menu.open {
    display: block;
  }
  
  .mobile-menu .link {
    display: block;
    padding: 0.75rem 2rem;
  }
  
  .mobile-menu .dropdownLink {
    padding-left: 3rem;
  }